<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-01 13:39:11
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-30 09:47:44
 * @FilePath: \uova\src\views\home\detail\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="indexList">
    <div class="index">
      <div class="neirong1">
        <div>
          <h2>系列介绍</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;《UOVA世界名画》项目于2022年2月发起于UOVA制委会，坐标，地球，上海。XX、Y2和Lily在闲暇时刻就会进行一系列的人物刻画，及UOVA制委会成员的随笔记录。此系列作品与现世名著意外相似，所以得名《UOVA世界名画》系列。艺术起源于生活并高于生活，高不高我们不关注，我们只是随时随地记录下身边正在发生的事情。
          </p>
        </div>
      </div>
      <!-- 详情页 -->
      <div class="xiangqing">
        <van-swipe class="my-swipe" @change="onChange" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="img3" src="../../../static/image/世界名画6幅/孕育.png" alt="" />
          </van-swipe-item>

          <van-swipe-item>
            <img class="img5" src="../../../static/image/世界名画6幅/祈.png" alt="" />
          </van-swipe-item>
          <van-swipe-item>
            <img class="img6" src="../../../static/image/世界名画6幅/第一次造访.jpeg" alt="" />
          </van-swipe-item>
          <!-- <van-swipe-item>
            <img class="img1" src="../../../static/image/世界名画6幅/Kiss.jpg" alt="" />
          </van-swipe-item>
          <van-swipe-item>
            <img class="img2" src="../../../static/image/世界名画6幅/创世.png" alt="" />
          </van-swipe-item>
               <van-swipe-item>
            <img class="img4" src="../../../static/image/世界名画6幅/无声的少女.png" alt="" />
          </van-swipe-item> -->
        </van-swipe>
      </div>
      <div class="neirong2">
        <div v-if="count == 0">
          <h2>《孕育》</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;顾名思义，襁褓中是UOVA宇宙，是执委会所有成员的心头肉。整幅画面仿佛一首空灵优雅的美妙音乐缓缓地奏出，如夜晚的春风抚慰着UOVA宇宙的梦境，也让欣赏者陶醉在这美轮美奂的梦境之中。
          </p>
        </div>

        <div v-if="count == 1">
          <h2>《祈》</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;UOVA主星的古老种族之一-气象种，UOVA气象局晴天事务部部长Soleil，这是她的日常工作之一，对晴空万里风和日丽阳光普照万物生机的感谢，表达对它们感恩与敬畏。
          </p>
        </div>
        <div v-if="count == 2">
          <h2>《第一次造访》</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;画面中记录了阳光明媚的清晨，UOVA制委会的Judy与她的助手在主城外的草原漫步，感受着新世界的阳光、微风和清晨的味道…这是UOVA执委会的成员初到UOVA主星后的第一个清晨，微风和煦阳光明媚…
          </p>
        </div>
        <!-- <div v-if="count == 3">
          <h2>《Kiss》</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;在遥远的UOVA星系中，也拥有着诸多智慧生命种族，他们一样有意识，有思想，有感情，有爱～！画中是UOVA主星的一对高等植物种族在表达对彼此的爱意。艺术永远来源于生活…
          </p>
        </div>
        <div v-if="count == 4">
          <h2>《创世》</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;画面记录了UOVA制委会的成员在触碰ROSSO的一瞬间…他们说：这里应当有UOVA宇宙，于是诞生了一个叫做UOVA的宇宙…
          </p>
        </div>
        <div v-if="count == 5">
          <h2>《无声的少女》</h2>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;XX作为UOVA制委会的一名成员，三大特长：画画、高冷、不说话。经常在一天的工作中安静的甚至不发出一点声音…所以，我们用一幅看似安静的画作来表达一个其实有内心有声响的少女
          </p>
        </div> -->
      </div>
      <div class="xiangqing2">
        <div class="neirong">
          <h3>用户须知</h3>
          &nbsp;&nbsp;&nbsp;&nbsp;1、数字藏品是依托区块链技术进行标识的经数字化的特定产品、艺术品和商品，包括但不限于数字画作、图片、音乐、视频、3D模型等各种样式。

          <br />

          &nbsp;&nbsp;&nbsp;&nbsp;2、数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户仅可为个人收藏、学习、研究、欣赏及展示目的使用，不得将数字藏品用于任何商业用途。

          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;3、数字藏品为虚拟数字商品，而非实物，一经兑换，不支持退换。

          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;4、每个数字藏品映射着特定区块链上的唯一序列号，不可篡改、不可分割，也不能互相替代。每一个数字藏品都代表特定作品、艺术品和商品限量发行的单个数字复制品，记录着其不可篡改的链上权利。

          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;5、数字藏品与虚拟货品等同质化代币存在本质不同，有特定作品、艺术品和商品的实际价值做支撑，也不具备支付功能等任何货币属性。因此，请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。
          <br />
          <br />
        </div>
      </div>
      <div>
        <van-button class="send" @click="$router.push('/home/first')">查看我的藏品</van-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { Toast } from 'vant';
export default {
  name: 'UovaIndex',
  data() {
    return {
      data: [],
      phone: void 0,
      imgflag: false,
      count: 0,
    };
  },
  mounted() {},

  methods: {
    onChange(index) {
      // Toast('当前 Swipe 索引：' + index);
      console.log(index);
      this.count = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.indexList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-image: url('../../../static/image/BG-01.png');
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% auto;
  .index {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .xiangqing {
      width: 90%;
      margin-left: 5%;
      height: auto;
      background-image: url('../../../static/image/藏品大小框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 2%;
      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        text-align: center;
        height: 200px;
        .img1 {
          height: 170px;
          margin: 0 auto;
          margin-top: 15px;
        }
        .img2 {
          width: 70%;
          height: auto;
          transform: translateY(8%);
        }
        .img3 {
          height: 170px;
          margin: 0 auto;
          margin-top: 15px;
        }
        .img4 {
          height: 170px;
          margin: 0 auto;
          margin-top: 15px;
        }
        .img5 {
          height: 170px;
          margin: 0 auto;
          margin-top: 15px;
        }
        .img6 {
          height: 170px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }
    }
    .neirong1 {
      width: 80%;
      margin-left: 5%;
      margin-top: 3%;
      line-height: 30px;
      padding: 30px 4%;
      background-image: url('../../../static/image/藏品内容框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 15px;
      /* padding-bottom: 30px;
      padding-top: 30px; */
    }
    .neirong2 {
      width: 90%;
      margin-left: 5%;
      margin-top: 2%;
      height: 280px;
      background-image: url('../../../static/image/藏品内容框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: bottom;
      padding-bottom: 20px;

      div {
        margin-top: 3%;
        width: 89%;
        line-height: 30px;
        padding: 6%;
      }
    }
    .xiangqing2 {
      width: 88%;
      margin-left: 5%;
      margin-top: 3%;
      line-height: 30px;
      /* color: #fff; */
      background-image: url('../../../static/image/展示框-分个.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 15px;
      padding-bottom: 0.2%;
      .neirong {
        padding: 8%;
        background-image: url('../../../static/image/活动流程框.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        h3 {
          text-align: center;
        }
      }
    }
  }
  .send {
    width: 50%;
    height: 50px;
    margin: 20px auto;
    background: none;
    border: none;
    background-image: url('../../../static/image/色相_饱和度 2.png');
    color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 25%;
  }
}
</style>
