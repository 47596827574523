/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-02 17:24:35
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-07 11:34:16
 * @FilePath: \H5 - 中秋\uova\src\views\home\first\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-01 17:47:42
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-04 20:25:42
 * @FilePath: \uova\src\views\home\first\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
// import request from '../../../utils/request/request.js';
export function loginCaptcha(params) {
  return axios({
    url: 'https://xicheng.zeroisle.com/login/loginCaptcha',
    method: 'get',
    params,
  });
}

// export function captchaLogin(data) {
//   return request({
//     url: '/login/captchaLogin',
//     method: 'post',
//     data,
//   });
// }
