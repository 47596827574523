<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-24 18:15:09
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-07 18:32:56
 * @FilePath: \uova\src\views\home\first\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <div class="inputList">
      <van-form validate-first>
        <!-- rgb(88 79 79 / 68%) -->
        <van-field
          class="yanzhengma"
          v-model="value"
          name="pattern"
          placeholder="| 手机号码"
          error-message-align="left"
          :rules="[{ pattern, message: '请输入格式正确的手机号', required: true }]"
        />
      </van-form>
      <van-field class="yanzhengma" v-model="sms" center clearable placeholder="|  验证码" maxlength="6">
        <template #button>
          <van-button class="send" v-if="!content" @click="loginCaptchaList">获取验证码</van-button>
          <van-button class="send" disabled v-else>{{ content }}</van-button>
        </template>
      </van-field>
    </div>
    <van-button class="login" @click="login" type="primary">确定</van-button>
  </div>
</template>
<script>
import { loginCaptcha } from './index';
import { Notify } from 'vant';
export default {
  data() {
    return {
      content: '',
      canClick: true,
      value: '',
      refreshData: null,
      sms: '',
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      query: {
        captcha: '',
        phone: '',
      },
      yanzhengma: '',
    };
  },
  methods: {
    login() {
      this.query.phone = this.value;
      if (this.value != '' && this.sms != '' && this.yanzhengma.includes(this.sms)) {
        Notify({ type: 'success', message: '登录成功' });
        this.$router.push({
          name: 'detail',
          params: {
            phone: this.query.phone,
          },
        });
      } else {
        if (this.value == '') {
          Notify({ type: 'danger', message: '手机号不能为空' });
        } else if (this.sms == '') {
          Notify({ type: 'danger', message: '验证码不能为空' });
        } else if (!this.yanzhengma.includes(this.sms)) {
          Notify({ type: 'danger', message: '验证码错误' });
        }
      }
    },
    loginCaptchaList() {
      const phone = this.value;
      loginCaptcha({ phone: phone }).then(res => {
        Notify({ type: 'success', message: '发送成功' });
        console.log(res);
        this.yanzhengma = res.data.data;
        this.countdowm();
      });
    },
    countdowm() {
      let totalTime = 60;
      if (!this.canClick) return; //节流
      this.canClick = false;
      this.content = totalTime + 's后重新倒计时';
      this.refreshData = window.setInterval(() => {
        totalTime--;
        this.content = totalTime + 's后重新倒计时';
        if (totalTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.content = '';
          totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
  },
  //beforeDestroy为实例销毁之前调用
  beforeDestroy() {
    if (this.refreshData) {
      clearInterval(this.refreshData);
      this.refreshData = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('../../../static/image/BG-02.png');
  background-size: 100% auto;
  .inputList {
    width: 100%;
    height: auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    .yanzhengma {
      width: 80%;
      height: 54px;
      margin-left: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 34px;
      background: none;
      background-image: url('../../../static/image/矩形 1 拷贝.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #fff;
      -webkit-text-fill-color: #fff;
      input {
        height: 50px;
        width: 60%;
        color: #fff;
      }
      ::-webkit-input-placeholder {
        color: rgb(228, 222, 222);
        font-size: 16px;
        font-weight: 400;
      }
      .send {
        height: 30px;
        color: #fff;
        border: none;
        background: none;
        background-image: url('../../../static/image/圆角矩形 3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .login {
    width: 50%;
    height: 50px;
    background: none;
    font-size: 18px;
    border: none;
    position: fixed;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../../../static/image/色相_饱和度 2.png');
    color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
