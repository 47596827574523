<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-01 13:39:11
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-08 13:06:59
 * @FilePath: \uova\src\views\home\detail\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="indexList">
    <div class="index">
      <!-- 详情页 -->
      <div></div>
      <div class="xiangqing2" v-for="v in data">
        <div class="img">
          <img :src="v.imageUrl" alt="" />
        </div>
        <div class="detailList">
          <p>区块链:文昌链</p>
          <p>
            持有人地址：
            <span style="width: 100%; word-break: break-word; font-size: 14px">{{ v.address }}</span>
          </p>
          <p>
            铸造HASH:
            <span style="width: 100%; word-break: break-word; font-size: 14px">{{ v.mintHash }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forwardingRequest } from './index';
import { Toast } from 'vant';
export default {
  name: 'UovaIndex',
  data() {
    return {
      data: [],
      phone: void 0,
      imgflag: false,
    };
  },
  mounted() {
    this.List();
    this.forwardingRequestList();
  },

  methods: {
    detailMap() {
      this.imgflag = true;
      console.log(this.imgflag);
    },
    List() {
      if (this.$route.params.phone) {
        this.phone = this.$route.params.phone;
      } else {
        this.phone = '';
      }
    },
    async forwardingRequestList() {
      const phone = this.phone;
      forwardingRequest({ phone: phone }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          if (!res.data.data.data.length == 0) {
            this.data = res.data.data.data;
          } else {
            Toast('手机号' + phone + '没有藏品');
          }
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indexList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-image: url('../../../static/image/BG-01.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #fff;
  .index {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    /* background-image: url('../../../static/image/背景.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .xiangqing2 {
      width: 90%;
      margin-left: 5%;
      margin-top: 3%;
      line-height: 30px;
      /* color: #fff; */
      background-image: url('../../../static/image/展示框-分个.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 15px;

      padding: 2% 0;
      .img {
        width: 90%;
        margin-left: 5%;
        height: 200px;
        background-image: url('../../../static/image/藏品大小框.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        margin-top: 10px;
        img {
          height: 170px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }
      .detailList {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 2.5%;
        padding: 10px;
        border-radius: 20px;
        margin-top: 10px;
        background-image: url('../../../static/image/藏品内容框-小.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
